







































import Vue from 'vue';
import {Test, TestGroup, TestInstance} from '~/models';

export default Vue.extend({
  name: 'PreExamOverviewPage',
  data() {
    return {
      testsWithExtraTry: [],
      panels: [0],
    };
  },
  async created() {
    const result = await Promise.all([
      this.$store.dispatch('test/fetchAll'),
      this.$store.dispatch('test/fetchAllGroups'),
      this.$store.dispatch('test/getExtraTries'),
    ]);
    this.testsWithExtraTry = result[2];
  },
  computed: {
    tests(): any[] {
      const tests = Test.query().withAll().all();
      return tests.filter(test => test.isPreExam && !!test.students.find(s => s.id === this.$store.state.auth.user.id));
    },
    studentTestStateTests(): any[] {
      const tests = this.$store.state.auth.user.studentTestStates.map(sts => ({...sts.test, state: sts.state}));
      return tests
          .filter(test => test.type === 'preExam')
          .filter(test => !TestGroup.query().withAll().all().find(tg => tg.tests.find(t => t.id === test.id)))
          .filter(test => !this.tests.find(t => t.id === test.id));
    },
    testGroups(): any[] {
      const testGroups = TestGroup.query().withAll().all();
      testGroups.unshift({title: this.$t('label.atoPreExams'), tests: this.studentTestStateTests} as any);
      testGroups.unshift({title: this.$t('label.directlyAssignedTests'), tests: this.tests} as any);

      return testGroups
          .filter(group => group.tests.length > 0)
          .map(group => {
            const instances: TestInstance[] = TestInstance.query().where('studentId', this.$store.state.auth.user.id).withAll().all();

            group.tests = group.tests
                .filter(test => test.type === 'preExam')
                .map(test => {
                  const passed = !!instances.find(instance => instance.testId === test.id && instance.passed && instance.insightGranted);
                  const notPassed = !!instances.find(instance => instance.testId === test.id && !instance.passed && instance.insightGranted);
                  const submitted = !!instances.find(instance => instance.testId === test.id && !instance.insightGranted);
                  const usedTries = instances.filter(instance => instance.testId === test.id).length;
                  const allowed = !test.dependentOnId || !!instances.find(instance => instance.testId === test.dependentOnId && instance.passed && instance.insightGranted);

                  let status = 'locked';

                  if (passed) {
                    status = 'passed';
                  } else if (submitted) {
                    status = 'submitted';
                  } else if (notPassed) {
                    status = 'notPassed';
                  } else if (allowed) {
                    status = 'available';
                  }

                  return {...test, passed, usedTries, allowed, status} as any;
                });
            return group;
          })
          .filter(group => group.tests.length > 0);
    },
    headers(): any[] {
      return [
        {text: this.$t('label.name'), value: 'displayName'},
        {text: this.$tc('p.try', 2), value: 'usedTries', align: 'center'},
        {text: this.$t('label.timeLimit'), value: 'timeLimit'},
        {text: this.$t('label.status'), value: 'status'},
      ];
    },
  },
});
